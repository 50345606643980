<template>
    <div v-if="work">
        <div>Works</div>
        <p>{{ work.title }}</p>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    name: "Works",

    data() {
        return {
            work: null,
        };
    },

    beforeRouteLeave(to, from, next) {
        this.onPageLeaving(next);
    },

    beforeRouteUpdate(to, from, next) {
        this.onPageLeaving(next)
    },

    created() {
        this.fetchData()
    },

    mounted() {

    },

    methods: {
        ...mapActions('main', ['setDataLoaded']),

        async fetchData() {
            const slug = this.$route.params.slug
            const url = `https://jsonplaceholder.typicode.com/posts/${slug}`

            await fetch(url)
                .then(response => response.json())
                .then((json) => {
                    this.work = json
                    
                    this.$nextTick(() => {
                        this.setDataLoaded(true)
                    })
                })

            console.log(this.work)
        },

        onPageLeaving(funcNext) {  
            this.setDataLoaded(false)

            console.log("page leaving")

            // Change de page une fois que le next est appelée
            funcNext()
        }
        
    }
};
</script>